(function () {
    angular.module('informaApp')
        .service('ProfileStore', () => {
            const storeManagers = [];

            return {
                getStoreManager(name) {
                    let manager = storeManagers.find(x => x.name === name);

                    if (!manager) {
                        manager = new ProfileStoreManger(name);
                        storeManagers.push(manager);
                    }

                    return manager;
                }
            }
        });

    class ProfileStoreManger {
        constructor(name) {
            this.name = name;
            this._store = [];
        }

        add(data, compare) {
            if (this._store.find(x => compare(x, data))) {
                return;
            }

            this._store.push(data);
        }

        find(compare) {
            const data = this._store.find(compare);

            return data || null;
        }

        getAll() {
            return this._store;
        }

        remove(compare) {
            this._store = this._store.filter(x => !compare(x));
        }
    }
})();